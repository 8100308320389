import axios, { AxiosInstance } from "axios"


export const authorizedApiClient = (token: string): AxiosInstance => axios.create({
    baseURL: process.env.API_URL_BASE,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const formDataApiClient = (token: string): AxiosInstance => axios.create({
    baseURL: process.env.API_URL_BASE,
    headers: {
        "Authorization": `Token ${token}`
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const unauthorizedApiClient = (headers?: { [key: string] : string}): AxiosInstance => axios.create({
    baseURL: process.env.API_URL_BASE,
    headers: {
        "Content-Type": "application/json",
        ...headers
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const noCredentialApiClient = (): AxiosInstance => axios.create({
    baseURL: process.env.API_URL_BASE,
    headers: {
        "Content-Type": "application/json"
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const plainClient = (): AxiosInstance => axios.create({})